<template>
  <div>
    <PersonnelDiscipline/>
  </div>
</template>

<script>
import PersonnelDiscipline from '../../components/personnelDiscipline.vue';

export default {
    name: 'HRvecPersonnelDiscipline',
    data() {
        return {};
    },
    mounted() {
    },
    methods: {},
    components: { PersonnelDiscipline }
};
</script>

<style lang="scss" scoped>

</style>